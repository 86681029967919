<template>
  <v-container fluid>
    <v-row dense justify="space-between">
      <v-col cols="12">
        <EscolhaOpcaoSelect :inscricao="inscricao" :opcoes="processo.opcoes"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import EscolhaOpcaoSelect from "@/components/pages/Inscricao/EscolhaOpcaoSelect";

export default {
  name: "InscricaoFormTHE",

  components: {
    EscolhaOpcaoSelect
  },

  props: {
    processo: {type: Object, required: true},
    inscricao: {type: Object, required: true}
  },

  data() {
    return {}
  },

  watch: {
    inscricao: {
      handler() {
        let inscricaoTHE = {
          inscricao: this.inscricao
        };
        this.$emit("input", inscricaoTHE);
      },
      deep: true
    },

  },

  methods: {
    ...rules
  }
};
</script>
